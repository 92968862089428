import React from "react"
import 'src/css/app_exclusives.css'
import 'src/css/flow.css'

// import SEO from 'src/components/seo'




const AppExclusives = () => (
      

<div className="master">




<div className="gradient">
  <div className="_1440-container nobottom toprow wf-section">
    <div className="row">
      <a href="https://www.laybuy.com/" target="_blank" rel="noopener noreferrer" className="w-inline-block"><img src={require('src/images/app-exclusives/Logo_White_1.png')} loading="lazy" sizes="(max-width: 767px) 154.796875px, 221.140625px"  alt="Laybuy Logo" className="logo" /></a>
    </div>
    <div className="row half above">
      <div className="header-padding">
        <div>
          <h1 className="size-2 white">Get your hands on more brands.</h1>
        </div>
      </div>
      <h1 className="size-6 white">Introducing App Exclusives.</h1>
      <a href="https://lyby.me/app-exclusives" target="_blank" rel="noopener noreferrer" className="link hidden">Discover all brands</a>
    </div>
  </div>
  <div className="div-block-5">
      <img src={require('src/images/app-exclusives/hero.png')} loading="lazy" data-w-id="21e050f1-14b1-39ed-2a8a-34f103770350"  alt="Get your hands on more brands" className="stores-bg" />

      </div>
  <div className="_1440-container app-exclusives-padding wf-section">
    <div className="right-column">
      <div className="div-block-8">
        <div className="div-block-7">
          <p className="right-white size-10 hidden-mobile">Scan to download the Laybuy app for full access to the new App Exclusives</p>
          <p className="right-white size-10 mobile-only">Download the Laybuy app for full access to the new App Exclusives</p>
        </div>
        <div className="div-block-7 apps">
          <a href="https://tools.applemediaservices.com/app/1465232796?country=gb" target="_blank" rel="noopener noreferrer" className="w-inline-block"><img src={require('src/images/app-exclusives/Download_on_the_App_Store_Badge_US-UK_RGB_blk_92918.png')} loading="lazy" alt="Download on the App Store" className="store" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.laybuy.laybuy" target="_blank" rel="noopener noreferrer" className="link-block w-inline-block"><img src={require('src/images/app-exclusives/google-play-badge_1.png')} loading="lazy" alt="Get it on Google Play" className="store" /></a>
        </div>
      </div>
      <img src={require('src/images/app-exclusives/QR-Code.png')} loading="lazy" alt="" className="image-5" />
    </div>
  </div>
  <div className="_1440-container wf-section how-it-works">
    <img src={require('src/images/app-exclusives/Group-1.png')} loading="lazy" alt="Virtual Card – How it Works. Laybuy" className="image mobile-visible" />
    <div className="row half">
      <h6 className="size-7 white">App Exclusives</h6>
      <div className="div-block">
        <div className="max-500">
          <h3 className="size-2 white">How it works.</h3>
          <div className="div-block-3"></div>
          <p className="size-8 white">We’ve got you covered with your virtual card so you can pay in 6 at hundreds more of your favourite brands. Exclusively available on the Laybuy app. <br />‍<br />From fashion to travel, we’ve added the biggest and best brands to make sure you can shop with your virtual card at the places you love. New App Exclusive stores to discover added daily!</p>
        </div>
      </div>
    </div>
    <img src={require('src/images/app-exclusives/Group-1.png')} loading="lazy"  alt="Virtual Card – How it Works. Laybuy" className="image mobile-hidden" />
  </div>
</div>
<div className="section black-bg notopztop seven-five steps">
<div className="_1440-container wf-section">
  <div className="row third above">
    <div className="div-block-12">
      <div className="div-block-13">
        <div className="blur"><img src={require('src/images/app-exclusives/1.png')} loading="lazy" alt="Step 1: Shop" className="full step-img step-1" /></div>
        <div className="div-block-14"><img src={require('src/images/app-exclusives/1.png')} loading="lazy" alt="Step 1: Shop" className="full step-img step-1" /></div>
      </div>
    </div>
    <h6 className="size-5 white">Step 1: Shop</h6>
    <div className="div-block">
      <div className="max-500">
        <p className="size-8 white">Okay, we’re pretty sure you’ve got this covered. Simply shop through the Laybuy app and checkout as normal.</p>
      </div>
    </div>
  </div>
  <div className="row third above">
    <div className="div-block-12">
      <div className="div-block-13">
        <div className="blur"><img src={require('src/images/app-exclusives/1.png')} loading="lazy" alt="" className="full step-img step-1" /></div>
        <div className="div-block-14"><img src={require('src/images/app-exclusives/2.png')} loading="lazy"  alt="Step 1: Shop" className="full step-img step-1" /></div>
      </div>
    </div>
    <h6 className="size-5 white">Step 2: Checkout</h6>
    <div className="div-block">
      <div className="max-500">
        <p className="size-8 white">Select ‘Credit/Debit Card’ at checkout. Laybuy will fill in your payment details with your virtual card. Easy.</p>
      </div>
    </div>
  </div>
  <div className="row third above">
    <div className="div-block-12">
      <div className="div-block-13">
        <div className="blur">
            <img src={require('src/images/app-exclusives/1.png')} loading="lazy" alt="" className="full step-img step-1" />
        </div>
        <div className="div-block-14"><img src={require('src/images/app-exclusives/3.png')} loading="lazy"  alt="Step 3: Pay in 6" className="full step-img step-1" /></div>
      </div>
    </div>
    <h6 className="size-5 white">Step 3: Pay in 6</h6>
    <div className="div-block">
      <div className="max-500">
        <p className="size-8 white">Your order will be processed like normal, but you’ll only be charged for your first instalment today.</p>
      </div>
    </div>
  </div>
</div>
</div>




















<div className="section black-bg notop ten-bottom">
<div className="_1440-container right more wid-auto wf-section" style={{float:"right"}}>
  <div className="sticky" style={{zIndex: "2500"}}>
    <img src={require('src/images/app-exclusives/Phone-with-Laybuy.png')} loading="lazy" alt="Phone with Laybuy" className="image-7" />
  </div>
  <div data-w-id="6a855dbb-5b4e-7197-c62e-9b22a9f9aa23" className="row two-third five-right above" >
    <div className="size-1 white tight-lead more-text">More stores</div>
    <div className="size-1 white tight-lead more-text">More brands</div>
    <div className="size-1 white tight-lead more-text">More looks</div>
    <div className="size-1 white tight-lead more-text">More trends</div>
    <div className="size-1 white tight-lead more-text">More choice</div>
    <div className="size-1 white tight-lead more-text">More style</div>
    <div className="size-1 purple-text tight-lead more-text">More Laybuy</div>
  </div>
</div>
</div>
<div data-w-id="6fa31b14-0c7b-4e55-003d-f5f93c5e0b2a" className="purple-bg finalcta">
<div className="_1440-container _15-h ztop wf-section">
  <div className="row half">
    <div className="header-padding notop">
      <div className="shopmoreeasy">
        <h6 className="size-2 white shopmoreeasy">Shop. More. Easy.</h6>
      </div>
    </div>
    <a href="https://lyby.me/app-exclusives" target="_blank" rel="noopener noreferrer" className="link _1top">Discover all brands</a>
  </div>
</div>

<img src={require('src/images/app-exclusives/final-apps-new.png')} loading="lazy" alt="Our Merchants – Laybuy" className="image-4 mobile-hidden" />
<img src={require('src/images/app-exclusives/final-apps-new-mobile.png')} loading="lazy" sizes="100vw" alt="Our Merchants – Laybuy" className="image-4 mobile-visible" />

</div>



<div className="section footer">
<div className="_1440-container nobottom wf-section">
  <div className="main-footer">
    <div className="div-block-9">
      <ul className="footer-link-list w-list-unstyled">
        <li>
          <a href="https://signup.laybuy.com/" target="_blank" rel="noopener noreferrer" className="footer-size">Sign Up</a>
        </li>
        <li>
          <a href="https://www.laybuy.com/uk/shop-here/" target="_blank" rel="noopener noreferrer" className="footer-size">Shop Directory</a>
        </li>
        <li>
          <a href="https://help.laybuy.com/hc/en-us" target="_blank" rel="noopener noreferrer" className="footer-size">Help &amp; Support</a>
        </li>
        <li>
          <a href="https://help.laybuy.com/hc/en-us" target="_blank" rel="noopener noreferrer" className="footer-size">FAQ</a>
        </li>
      </ul>
      <p className="footer-disclaimer">Mastercard® is a registered trademark, and the circles design is a trademark of Mastercard Incorporated. The Laybuy Card is issued by EML Money DAC pursuant to licence by Mastercard International Inc. The e-money associated with your card is issued by EML Money DAC, authorised by the Central Bank of Ireland under the European Union (Payment Services) Regulations 2018 (Ref C95957).</p>
    </div>
    <div className="right-column tab-wrap">
      <div className="div-block-8 nomax">
        <div className="div-block-7 wrap">
          <a href="https://tools.applemediaservices.com/app/1465232796?country=gb" target="_blank" rel="noopener noreferrer" className="footer-app-stores w-inline-block"><img src={require('src/images/app-exclusives/Download_on_the_App_Store_Badge_US-UK_RGB_blk_92918.png')} loading="lazy" alt="Download on the App Store" className="app-store" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.laybuy.laybuy" target="_blank" rel="noopener noreferrer" className="footer-app-stores w-inline-block"><img src={require('src/images/app-exclusives/google-play-badge_1.png')} loading="lazy"  alt="Get it on Google Play" className="app-store"/></a>
        </div>
      </div>
      <div className="tab-hidden">
        <div className="center">Scan to get the app</div>
        <img src={require('src/images/app-exclusives/QR-Code.png')} loading="lazy" alt="QR Code" className="image-5" />
      </div>
    </div>
  </div>
  <div className="footer-legal">
    <a href="http://laybuy.com" target="_blank" rel="noopener noreferrer" className="footer-legal-logo w-inline-block"><img src={require('src/images/app-exclusives/Logo_Black_1.png')} loading="lazy" alt="Laybuy Logo" className="image-6" /></a>
    <div className="div-block-10">
      <div className="footer-legal-text full">© Laybuy Holdings Ltd.</div>
      <a href="https://www.laybuy.com/uk/card-terms" target="_blank" rel="noopener noreferrer" className="footer-legal-text">Terms</a>
      <a href="https://www.laybuy.com/uk/privacy" target="_blank" rel="noopener noreferrer" className="footer-legal-text">Privacy</a>
    </div>
  </div>
</div>
</div>
<script src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61298d3d788c9e02d58309b8" type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous"></script>
</div>


  )

export default AppExclusives